// Here you can add other styles
.main {
  margin-top: 30px;
}

.bold-text {
  font-weight: bold;
}

.full-width-modal-header {
  .modal-title {
    width: 100%
  }
}

.pointer {
  cursor: pointer;
}

.invalid-feedback {
  display: block;
}

.invalid-control {
  border-color: #e55353;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTU1MzUzIiB2aWV3Qm94PSIwIDAgMTIgMTIiPjxjaXJjbGUgY3g9IjYiIGN5PSI2IiByPSI0LjUiLz48cGF0aCBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNNS44IDMuNmguNEw2IDYuNXoiLz48Y2lyY2xlIGN4PSI2IiBjeT0iOC4yIiByPSIuNiIgZmlsbD0iI2U1NTM1MyIgc3Ryb2tlPSJub25lIi8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding-right: calc(1.5em + 0.75rem);
  background-position: right calc(0.375em + 0.1875rem) center;
}